import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import messages from 'utils/messages';
import EditPencil from 'images/common/edit-pencil';
import OutsideClickHandler from 'react-outside-click-handler';

class Card extends Component {
  state = {
    showDropdown: false,
  };

  toggleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showDropdown: true });
  };

  closeMenu = () => {
    this.setState({ showDropdown: false });
  };

  onActionOutsideClick = () => {
    this.setState({ showDropdown: false });
  };

  render() {
    const { noGrid, secondaryContent, id } = this.props;

    return (
      <div className={classnames('content-box', this.props.className, {
        card_loading: this.props.isLoading,
      })}
      >
        {
          this.props.customText
          && (
            <Button
              id={id}
              className={classnames('overview__edit', `overview__edit_${this.props.color}`, { [this.props.color]: this.props.color })}
              onClick={this.props.action}
            >
              {this.props.customText}
            </Button>
          )
        }
        {
          this.props.menu
          && (
            <span className="actions2">
              <OutsideClickHandler onOutsideClick={this.onActionOutsideClick}>
                <Button
                  id={id}
                  className={classnames('overview__edit', `overview__edit_${this.props.color}`, { [this.props.color]: this.props.color })}
                  onClick={this.toggleAction}
                >
                  {this.props.menu}
                </Button>
                {this.props.children && (
                  <div
                    className={classnames('actions__dropdown', {
                      actions__dropdown_visible: this.state.showDropdown,
                    })}
                  >
                    {this.state.showDropdown
                      && React.Children.map(this.props.children, (child) => {
                        if (child) {
                          return React.cloneElement(child, {
                            closeMenu: this.closeMenu,
                            onToogleMenu: this.toggleAction,
                          });
                        }
                      })}
                  </div>
                )}
              </OutsideClickHandler>
            </span>
          )
        }
        {this.props.onEdit
          && (
            <Button
              id={id}
              className={classnames('overview__edit', `overview__edit_${this.props.color}`)}
              onClick={this.props.onEdit}
            >
              <EditPencil />
              <FormattedMessage {...messages.edit} />
            </Button>
          )
        }
        <div className="overview__title">
          {this.props.title}
        </div>
        {this.props.subTitle
          && <div className="overview__subtitle">{this.props.subTitle}</div>
        }
        {noGrid && this.props.content}
        {!noGrid && (
          <div className={classnames("grid-row", {
            'width-100': this.props.has100Width
          })}>
            {this.props.content}
          </div>
        )
        }
        {secondaryContent && (
          <div className={classnames("grid-row", {
            'width-100': this.props.has100Width
          })}>
            {this.props.secondaryContent}
          </div>
        )
        }
      </div>

    );
  }
}

Card.propTypes = {
  color: PropTypes.string,
  subTitle: PropTypes.any,
  customText: PropTypes.any,
  action: PropTypes.any,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  color: 'accent0',
  className: 'overview',
};

export default Card;
