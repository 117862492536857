import { createSelector } from 'reselect';

export const selectGlobal = state => state.global;

export const selectRouter = state => state.router;

export const makeSelectLoading = () => createSelector(selectGlobal, globalState => globalState.get('loading'));

export const makeSelectDevice = () => createSelector(selectGlobal, globalState => globalState.get('device'));

export const getNotificationState = () => createSelector(selectGlobal, globalState => globalState.get('showNotification'));

export const getSidebarStatus = () => createSelector(selectGlobal, globalState => globalState.get('hideFullSidebar'));

export const getDatasetSidebarStatus = () => createSelector(selectGlobal, globalState => globalState.get('hideFullDatasetSidebar'));

export const getInfraBanner = () => createSelector(selectGlobal, globalState => globalState.get('showPendingInfraBanner'));

export const getIsLoggedIn = () => createSelector(selectGlobal, globalState => globalState.get('isLoggedIn'));

export const makeSelectUser = () => createSelector(selectGlobal, globalState => globalState.get('userData'));

export const makeSelectError = () => createSelector(selectGlobal, globalState => globalState.get('errors'));

export const getUploadTrackerInfo = () => createSelector(selectGlobal, globalState => globalState.get('uploadTracker'));
