import { routeMapping } from 'api/constants';
import PrivateRoute from 'components/PrivateRoute';

import App from 'containers/App';
import { updateDeviceType } from 'containers/App/actions';
import { makeSelectDevice } from 'containers/App/selectors';

import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import PropTypes from 'prop-types';
import React, { useState, Suspense, lazy, useEffect } from 'react';
import {
  isMobile,
} from 'react-device-detect';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import zohoAdd from './utils/zohoAdd';
import zohoRemove from './utils/zohoRemove';
import loadable from '@loadable/component';
const Register = lazy(() => import('containers/Auth/Register'));
const Pricing = lazy(() => import('containers/Plans/Pricing'));
const CreateWorkspace = lazy(() => import('containers/Settings/Workspace/Create'));
const AcceptInvite = lazy(() => import('containers/AcceptInvite'));
const VerifyEmail = lazy(() => import('containers/AcceptInvite/VerifyEmail'));
const ResendEmail = lazy(() => import('containers/AcceptInvite/ResendEmail'));
const InvalidToken = lazy(() => import('containers/AcceptInvite/InvalidToken'));
const Login = lazy(() => import('containers/Auth/Login'));
const ChangePassword = lazy(() => import('containers/Auth/ForgotPassword/ForceResetPassword'));
const ForgotPassword = lazy(() => import('containers/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('containers/Auth/ForgotPassword/ResetPassword'));
const Marketplace = lazy(() => import('pages/public/PublicMarketplaceList'));
const MarketplaceDetails = lazy(() => import('pages/public/PublicMarketplace'));

import {version} from '../package.json';

const AppContainer = ({ workspace, updateDeviceType }) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      updateDeviceType({ width: window.innerWidth, isMobile });
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [updateDeviceType]);

  const getContainerClassName = () => {
    if (!workspace) {
      return 'color-theme-viso contrast-theme-light light';
    }

    return `color-theme-${workspace.color || 'viso'} contrast-theme-${workspace.contrast} ${workspace.contrast}`;
  };

  const handleReload = () => {
    // alert(`Good News! New update v${version} is available now`)
    localStorage.setItem('version', version);
    // window.location.reload(true); // Force reload from the server
  };

  useEffect(() => {
    if (navigator.serviceWorker) {
      // navigator.serviceWorker.getRegistration().then(registration => registration && registration.unregister());
    }

    // Check for new version from localStorage
    const isNewVersion = localStorage.getItem('version') !== version;
    setIsNewVersionAvailable(isNewVersion);
    if(isNewVersion) {
      handleReload()
    }

  }, []);

  const isAuthenticated = !!window.localStorage.accessToken;
  const { pathname } = window.location;


  if (pathname.startsWith('/pricing') || pathname.startsWith('/marketplace')) {
    zohoRemove();
  } else {
    zohoAdd();
  }

  return (
    <div className={getContainerClassName()}>
      <Suspense fallback={<div>Loading</div>}>
        <Router basename="/">
          <Routes>
            <Route exact path="/login/" element={<Login />} />
            <Route exact path="/logout" component={<Login />} />
            <Route exact path="/pricing/" component={<Pricing/>} />
            <Route
              path="/marketplace"
              element={<Marketplace />}/>
            {!isAuthenticated
              && <Route exact path="/marketplace/:id" element={<MarketplaceDetails />} />}
            <Route exact path={routeMapping.REGISTER.path} element={<Register/>} />
            <Route exact path={routeMapping.NEW_WORKSPACE.path} element={<CreateWorkspace/>} />
            <Route
              path={routeMapping.INVALID_TOKEN.path}
              element={<InvalidToken/>}
            />
            <Route
              path={routeMapping.RESEND_EMAIL.path}
              element={<ResendEmail/>}
            />
            <Route
              path="/reset-password/*"
              element={<ForgotPassword/>}
            />
            <Route
              path="/change-password"
              element={<ChangePassword/>}
            />
            <Route
              path="/login/resetpassword"
              element={<ResetPassword/>}
            />
            <Route
              exact path="/forgot-password"
              element={<ForgotPassword/>} />
            <Route
              path="/invitation/:token"
              element={<AcceptInvite/>}
            />
            <Route
              path={routeMapping.VERIFY_EMAIL.path}
              element={<VerifyEmail/>}
            />
            <Route
              path="/*"
              element={
                <PrivateRoute
                  element={App}
                />
              }
            />
            {/* <Navigate from="/" to={`${workspace && workspace.name}${routeMapping.SETTINGS.path}`} /> */}
          </Routes>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Router>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => ({
  workspace: makeSelectFetchWorkspace()(state),
  deviceSizeType: makeSelectDevice()(state),
});

AppContainer.propTypes = {
  workspace: PropTypes.object,
  updateDeviceType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateDeviceType })(AppContainer);
