import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class CustomInput extends React.Component {
  state = {
    showError: false,
    value: "",
  };

  componentDidMount() {
    this.setState({ value: this.props.value === 0 ? "0.0" : this.props.value });
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if ((nextProps.value !== this.state.value && nextProps.type !== 'number')) {
      return this.setState({ value: nextProps.value || ""});
    }

    if ((nextProps.value !== this.state.value && nextProps.type === 'number')) {
      return this.setState({ value: nextProps.value});
    }
  }

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    if(this.props.type === 'number' && (!e.currentTarget.value || e.currentTarget.value === "0")) {
      this.setState({ value: e.currentTarget.value });
    }

    this.setState({ showError: true });
  };

  onChange = (e) => {
    const { aplhaNumericOnly } = this.props;

    const text = e.currentTarget.value;
    const regex = /^[a-z0-9-_]+$/i;
    const isValid = text.match(regex);

    if (!text) {
      this.props.onChange(e);
    }

    if (isValid) {
      this.setState({ value: e.currentTarget.value });
      return this.props.onChange(e);
    }

    if (!aplhaNumericOnly) {
      this.setState({ value: e.currentTarget.value });
      return this.props.onChange(e);
    }
  };

  render() {
    const { showError, value } = this.state;
    const {
      parentClass,
      postFix,
      maxLength,
      postIcon,
      postIconClass,
      disabled,
      hideHint,
      checked,
      footer,
      type,
      footerClass,
      customClass,
      isCheckbox,
      title
    } = this.props;

    const inputClass = classNames({
      "text-field__input": !isCheckbox,
      "text-field__input_filled": value || !!this.props.defaultValue || (type === 'number' && value >= 0),
      "text-field_readonly": this.props.readOnly,
      "text-field__input_disabled": this.props.disabled,
    });

    const parentCustomClass = classNames({
      "text-field": !isCheckbox,
      "text-field_invalid": this.props.isInvalid && showError && !hideHint,
      [`text-field_${parentClass}`]: this.props.parentClass,
      [customClass]: this.props.customClass,
      "mx-b-40": footer
    });

    return (
      <div className={parentCustomClass} title={title}>
        <input
          {...this.props.input}
          id={this.props.id}
          data-cy={this.props.dataCy}
          checked={checked}
          autoFocus={this.props.autoFocus}
          type={this.props.type || "text"}
          className={inputClass}
          maxLength={maxLength}
          max={this.props.max}
          step={this.props.step}
          min={this.props.min}
          disabled={disabled}
          readOnly={this.props.readOnly}
          onChange={this.onChange}
          onBlur={this.onBlur}
          name={this.props.name}
          value={this.state.value}
        />
        <h5 className="text-field__label">{this.props.label}</h5>
        <div className="text-field__underscore" />
        <div
          className={classNames("text-field__hint text-field__hint_r", {
            "show-password":
              showError && this.props.hintText && !this.props.hideHint,
          })}
        >
          {this.props.hintText}
        </div>
        {postFix && <div className="text-field__postfix">{postFix}</div>}
        {postIcon && (
          <div className={`text-field__postIcon ${postIconClass}`}>
            {postIcon}
          </div>
        )}
        {footer && (
          <div
            className={classNames("text-field__hint text-field__hint_r show-password text-field__footer", {
              [footerClass]: footerClass
            })}
          >
            {footer}
          </div>
        )}
      </div>
    );
  }
}

CustomInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  parentClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isInvalid: PropTypes.any,
};
