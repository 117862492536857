const zoho = () => {
  const zohoElement = document.getElementById('zohohc-asap-web-helper-main');

  if (zohoElement) {
    zohoElement.remove();
  }
  const existingScript = document.getElementById('zoho');
  if (existingScript) {
    existingScript.remove();
  }
};

export default zoho;
