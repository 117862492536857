import axios from "axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import axiosObj from "./config";

export const DATASET_API_URL = `${process.env.API_URL}/svc-dataset/graphql`;
// export const DATASET_API_URL = `http://localhost:3002/graphql`;
const MODEL_API_URL = `${process.env.API_URL}/model/graphql`;

const DATASET_ASSOCIATE_MODEL_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  source: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  license: true,
  modelSize: true,
  imageSize: true,
  labels: true,
  url: true,
  createdAt: true,
  updatedAt: true
};

const DATASET_ASSOCIATE_JOB_RETURN_VALUES = {
  _id: true,
  name: true,
  status: true,
  estimatedCompletion: true,
  mAP: true,
  duration: true,
  createdAt: true,
  baseModel: {
      _id: true,
      name: true,
      modelArchitecture: true,
      modelType: true,
      framework: true,
      imageSize: true,
      license: true,
  },
  dataset: {
      _id: true,
      name: true,
      mediaType: true,
      version: true,
  },
  experiment: {
      _id: true,
      name: true,
      description: true,
  },
  createdBy: {
      _id: true,
      firstName: true,
      lastName: true
  }
};

export const PROJECT_TYPE = {
  id: true,
  name: true,
  createdAt: true,
  updatedAt: true,
};

export const AUTHOR = {
  id: true,
  name: true,
};

export const TAG_ITEMS = {
  key: true,
  value: true
}

export const TAG_RESPONSE = {
  values: [TAG_ITEMS]
}

export const DATASET_DETAIL_TYPE = {
  id: true,
  name: true,
  project: PROJECT_TYPE,
  description: true,
  tags: true,
  mediaType: true,
  source: true,
  author: true,
  createdAt: true,
  updatedAt: true,
  createdBy: AUTHOR,
  updatedBy: AUTHOR,
  info: true,
  version: true,
  status: true,
  brainMethods: true,
  annotationLabels: true
};

export const DATASET_TYPE = {
  id: true,
  name: true,
  project: PROJECT_TYPE,
  description: true,
  tags: true,
  mediaType: true,
  source: true,
  author: true,
  createdAt: true,
  updatedAt: true,
  createdBy: AUTHOR,
  updatedBy: AUTHOR,
  info: true,
  version: true,
  status: true,
  demoData: true,
  annotationLabels: true
};

export const DATASET_STATS = {
  totalImages: true,
  unAnnotatedImages: true,
  annotatedImages: true,
  totalAnnotations: true,
  classesAcrossAnnotations: {
    count: true,
    class: true,
    share: true,
    representation: true
  },
  classesAcrossImages: {
    count: true,
    class: true,
    share: true,
    representation: true
  },
  totalClasses: true,
  avgImagesPerClass: true,
  avgAnnotationsPerImage: true,
  annotatedPercentage: true,
  annotationDistributionGraph: {
    _id: true,
    totalAnnotations: true,
  }
};

export const DATASET_VALIDATE = {
  blockers: true,
  warnings: true,
};

export function getDatasets(params) {
  const query = {
    query: {
      datasets: {
        ...DATASET_TYPE,
        __args: params
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDatasetDetail({ id }) {
  const query = {
    query: {
      dataset: {
        __args: {
          id,
        },
        ...DATASET_DETAIL_TYPE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProjects() {
  const query = {
    query: {
      projects: {
        ...PROJECT_TYPE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getTags(args) {
  const query = {
    query: {
      aggregate: {
        __args: args,
        ...TAG_RESPONSE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAggregate(args) {
  const query = {
    query: {
      aggregates: {
        __args: args
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLabels(args) {
  const query = {
    query: {
      getLabels: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getOtherLabels(args) {
  const query = {
    query: {
      getOtherLabels: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createDataset(args) {
  const query = {
    mutation: {
      createDataset: {
        __args: args,
        id: true,
        name: true
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addSamples(args) {
  const query = {
    mutation: {
      addSamples: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function setDataset(args) {
  const query = {
    mutation: {
      setDatasetWithLock: {
        __args: args,
        locked: true,
        lockedBy: {
          id: true,
          name: true
        }
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unlockFiftyOne() {
  const query = {
    mutation: {
      unlockFiftyOne
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createProject(args) {
  const query = {
    mutation: {
      createProject: {
        __args: args,
        name: true,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function saveDataset(args) {
  const query = {
    mutation: {
      saveDataset: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cloneDataset(args) {
  // const query = {
  //   mutation: {
  //     cloneDataset: {
  //       __args: args,
  //       id: true
  //     },
  //   },
  // };
  // const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  // return axiosObj({
  //   method: "POST",
  //   url: DATASET_API_URL,
  //   data: {
  //     variables: {},
  //     query: gqlQuery,
  //   },
  // });
  let tags = args.tags.length ? `[${args.tags.map(item => `"${item}"`)}]` : "[]";

  var data = JSON.stringify({
    query: `mutation($form: JSON) {
      cloneDataset (
        datasetId: "${args.datasetId}",
        name: "${args.name}",
        author: "${args.author}",
        source: "${args.source}",
        tags: ${tags},
        projectId: "${args.projectId}",
        form:$form
    ) {
      name,
      id
    }
  }`,
    variables: args.form || { form: "" }
  })

  if(args.training || args.validation || args.testing) {
  
    data = JSON.stringify({
      query: `mutation($form: JSON) {
        cloneDataset (
          datasetId: "${args.datasetId}",
          name: "${args.name}",
          author: "${args.author}",
          source: "${args.source}",
          tags: ${tags},
          projectId: "${args.projectId}",
          training: "${args.training || ""}",
          validation: "${args.validation || ""}",
          testing: "${args.testing || ""}",
          form:$form
      ) {
        name,
        id
      }
    }`,
      variables: args.form || { form: "" }
    })
  }

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: data,
  });
}

export function updateDataset(args) {
  const query = {
    mutation: {
      updateDataset: {
        __args: args,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function loadCvatDataset(args) {
  const query = {
    mutation: {
      loadCvatDataset: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function loadCvatDatasetSelected(args) {
  const query = {
    mutation: {
      loadCvatDatasetSelected: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function mergeAnnotation(args) {
  const query = {
    mutation: {
      mergeAnnotation: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addVideoSamples(args) {
  const query = {
    mutation: {
      addVideoSamples: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteDataset(args) {
  const query = {
    mutation: {
      deleteDataset: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteDatasets(args) {
  const query = {
    mutation: {
      deleteDatasets: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function setView(args) {
  var data = JSON.stringify({
    query: `query($form: JSON) {
    setView (
        datasetId: "${args.datasetId}",
        filters:$form
    )
  }`,
    variables: args.form
  })

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: data,
  });
}

export function getSamples(args) {
  var data = JSON.stringify({
    query: `query MyQuery {
    samples(datasetId: "${args.datasetId}") {
      edges {
        node {
          __typename
          ... on ImageSample {
            id
            sample
            }
          ... on VideoSample {
            id
            sample
            }
          ... on PointCloudSample {
            id
            sample
            }
          }
        }
      }
  }`,
    variables: {},
  });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: data,
  });
}

export function filterByTags(args) {
  const query = {
    query: {
      filterByTags: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateView(args) {
  const query = {
    query: {
      updateView: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function takeDataset(args) {
  const query = {
    mutation: {
      takeDataset: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function splitDataset(args) {
  const query = {
    mutation: {
      splitDataset: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getSamplesData(params) {
  const query = {
    query: {
      getSampleData: {
        __args: params,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getUniquenessScore(args) {
  const query = {
    mutation: {
      uniquenessCalculate: {
        __args: {
          id: args
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getSimilarityScore(args) {
  const query = {
    mutation: {
      similarityCalculate: {
        __args: {
          id: args
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteSample(args) {
  const query = {
    mutation: {
      deleteSample: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function calculationDataset() {
  const query = {
    query: {
      calculationDataset: {
        ...DATASET_TYPE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cancelCalculation() {
  const query = {
    mutation: {
      cancelCalculation
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function datasetClassList(args) {
  const query = {
    query: {
      datasetClassList: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function datasetStats(args) {
  const query = {
    query: {
      datasetStats: {
        __args: args,
        ...DATASET_STATS
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function validateDataset(args) {
  const query = {
    query: {
      validateDataset: {
        __args: args,
        ...DATASET_VALIDATE
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function importAnnotations(args) {
  const query = {
    mutation: {
      importAnnotations: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: DATASET_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function associatedModels(args) {
  const query = {
    query: {
      associatedModels: {
        __args: args,
        ...DATASET_ASSOCIATE_MODEL_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function associatedTrainingJobs(args) {
  const query = {
    query: {
      associatedTrainingJobs: {
        __args: args,
        count: true,
        data: DATASET_ASSOCIATE_JOB_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}