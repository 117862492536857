import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProcessPage from 'components/ProcessPage';
import { CARD_VIEW, TABLE_VIEW } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import AHref from 'components/AHref';
import messages from 'utils/messages';
import { isSmallerDevice } from 'utils/common';
import { makeSelectDevice } from 'containers/App/selectors';

class SelectList extends React.Component {
  state = {
    activeView: TABLE_VIEW,
  };

  updateView = (view) => {
    this.setState({ activeView: view });
  };

  componentDidMount() {
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType
      && isNextSmallerDeviceType
      && nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice
      && nextProps.deviceSizeType !== this.props.deviceSizeType
      && nextProps.deviceSizeType === 'desktop'
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }


  renderActions() {
    const { activeView } = this.state;
    const { customClass } = this.props;

    const viewHeadlineClass = classNames(
      'actions-nav__icon actions-nav__icon_cards',
      {
        'actions-nav__icon_active': activeView === CARD_VIEW,
        [customClass]: customClass,
      },
    );

    const viewListClass = classNames(
      'actions-nav__icon actions-nav__icon_rows',
      {
        'actions-nav__icon_active': activeView === TABLE_VIEW,
        [customClass]: customClass || 'accent0',
      },
    );

    return (
      <>
        <div className="actions-nav">
          <div className="actions" />
          <AHref
            className={viewListClass}
            disableHoverEffect={activeView !== TABLE_VIEW}
            onClick={() => this.updateView(TABLE_VIEW)}
          />
          <AHref
            className={viewHeadlineClass}
            disableHoverEffect={activeView !== CARD_VIEW}
            onClick={() => this.updateView(CARD_VIEW)}
          />
        </div>
      </>
    );
  }

  render() {
    const { activeView } = this.state;
    const {
      children,
      selectedIds = [],
      onSelect,
      onReset,
      customClass,
      disableSkip,
      confirmText,
    } = this.props;

    return (
      <ProcessPage
        onCancel={this.props.onClose}
        onBack={this.props.onBack}
      >
        {scrollRef => (
          <>
            <div className="headline">
              <h1 className="headline__title">
                {this.props.title}
              </h1>
              <h3 className="headline__subtitle">
                {this.props.subHeader}
              </h3>
            </div>
            <div className="">
              {React.Children.map(children, child => React.cloneElement(child, {
                color: customClass,
                scrollRef,
                selectedIds: selectedIds || [],
                onSelect,
                activeView,
                onReset,
              }))}
            </div>
            {this.props.onSubmit
            && (
              <div className="foot-nav">
                <button
                  className={classNames('round-button round-button_br round-button_primary round-button_forw', {
                    [`round-button_${customClass}`]: customClass,
                    'round-button_disabled': (confirmText && (selectedIds && selectedIds.length !== 1)) || (disableSkip && !selectedIds.length),
                    'round-button_enabled': this.props.allowSkip,
                  })
                  }
                  onClick={() => {
                    if (disableSkip && selectedIds.length) {
                      this.props.onSubmit(selectedIds);
                    }

                    if (!disableSkip) {
                      this.props.onSubmit(selectedIds);
                    }
                  }
                  }
                >
                  {confirmText && !this.props.allowSkip
                    ? (
                      <span
                        className="round-button__label"
                      >
                        {confirmText}
                      </span>
                    )
                    : (
                      <span
                        className="round-button__label"
                      >
                        {(selectedIds && selectedIds.length)
                          ? <FormattedMessage {...messages.assign} />
                          : <FormattedMessage {...messages.skip} />
                        }
                      </span>
                    )
                  }
                </button>
              </div>
            )
            }
          </>
        )}
      </ProcessPage>
    );
  }
}

SelectList.propTypes = {
  title: PropTypes.string,
  onReset: PropTypes.func,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  onClose: PropTypes.func,
  customClass: PropTypes.string,
  subHeader: PropTypes.string,
  selectedIds: PropTypes.array,
  activeView: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  deviceSizeType: makeSelectDevice(),
});

const withConnect = connect(
  mapStateToProps,
);

export default withConnect(SelectList);
