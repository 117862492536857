import classNames from 'classnames';
import { appLanguages } from 'i18n';
import {
  isArray, isEmpty, isEqual, isNull, uniq, xorWith,
} from 'lodash';
import moment from 'moment';
import deLang from 'moment/locale/de';
import 'moment/locale/fr';
import postalCodes from 'postal-codes-js';
import React from 'react';
import { Box, DollarSign, GitPullRequest } from 'react-feather';
import * as timeago from 'timeago.js';
import history from 'utils/history';
import { DEFAULT_SHORTENED_TEXT_COUNT } from './constants';

moment.locale('de', deLang);

const DOMAIN_NAME = '.viso';

export const snakeCaseToTitleCase = (str) => {
  if (!str) {
    return '';
  }

  return str
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
};

export const isLocalhost = window.location.href.startsWith('http://localhost')
  || window.location.href.includes('192.168');

export const dateFormatter = (value, isTime) => {
  const dateTime = isNaN(value) ? value : Number(value);

  if (isTime) {
    return moment(dateTime).format('D MMM YYYY HH:mm');
  }

  return moment(dateTime).format('D MMM YYYY');
};

export const lastScanDateFormatter = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('D.M.YYYY HH:mm');
};

export const addMonthDateFormatter = (value, isTime, months) => {
  const dateTime = isNaN(value) ? value : Number(value);

  if (isTime) {
    return moment(dateTime)
      .add(months, 'M')
      .startOf('month')
      .format('D MMM YYYY HH:mm');
  }

  return moment(dateTime)
    .add(months, 'M')
    .startOf('month')
    .format('D MMM YYYY');
};

export const convertDefaultStatusToStatus = (status) => {
  if (!status) {
    return '-';
  }
  const statusList = status.split('_');

  const result = statusList.reduce((allStatus, item) => `${allStatus} ${item}`);
  return snakeCaseToTitleCase(result);
};

export const dateFormatter4 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('MMM D, YYYY');
};

export const dateFormatter5 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('MMM-DD-YYYY');
};


export const dateFormatter2 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('D MMM, YYYY');
};

export const dateFormatter3 = (value) => {
  const dateTime = isNaN(value) ? value : Number(value);

  return moment(dateTime).format('MMMM YYYY');
};

export const getLastUpdateTime = date => moment(date, 'YYYYMMDD').fromNow();

export const detailDateFormatter = (value, isTime) => {
  const dateTime = isNaN(value) ? value : Number(value);

  if (isTime) {
    return moment(dateTime).format('DD.MM.YYYY HH:mm');
  }

  return moment(dateTime).format('DD.MM.YYYY');
};

export const getDuration = value => timeago.format(value);

export const truncate = (name, size) => {
  const count = size || DEFAULT_SHORTENED_TEXT_COUNT;

  if (!name) {
    return name;
  }

  if ((name && name.length <= count) || typeof name === 'number') {
    return name;
  }

  const subString = name && name.substring(0, count - 1);

  return `${subString}...`;
};

export const getColumns = (data, columnName) => uniq(data.map(item => item[columnName]));

export const getLangauge = (lang) => {
  const languages = {
    de: 'Deutsch',
    en: 'English',
  };

  return languages[lang] || 'English';
};

export const getLangOptions = () => Object.keys(appLanguages).map(item => ({
  value: appLanguages[item].defaultLanguage,
  label: appLanguages[item].name,
}));

export const getInitials = (firstName, lastName) => {
  if (!lastName && firstName) {
    const nameList = firstName.split(' ');

    if (nameList.length === 1) {
      return `${nameList[0][0].toUpperCase()}`;
    }

    return `${nameList[0][0].toUpperCase()}${nameList[1][0].toUpperCase()}`;
  }

  const firstNameInitial = firstName ? firstName[0].toUpperCase() : '';
  const lastNameInitial = lastName ? lastName[0].toUpperCase() : '';

  return `${firstNameInitial}${lastNameInitial}`;
};

export const getSubDomain = () => {
  const subDomainList = window.location.host.split(DOMAIN_NAME);

  if (subDomainList.length > 1) {
    return subDomainList[0];
  }

  return '';
};

export const getShortLang = {
  english: 'en',
  german: 'de',
  french: 'fr',
  italy: 'it',
};

export const getLanguage = {
  english: 'English',
  german: 'Deutsch',
};

export const getDevicePrice = (value, plan, interval) => {
  if (!(plan && plan.tiers)) {
    return 0;
  }

  const planTiers = plan.tiers.map((tier, index) => {
    if (index === plan.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      const previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  if (interval === 'year') {
    return `${parseFloat(price / 100 / 12).toFixed(2)}`;
  }

  return `${parseFloat(price / 100).toFixed(2)}`;
};

export const getDevicePricePerSeat = (value, plan) => {
  if (!(plan && plan.tiers)) {
    return 0;
  }

  const planTiers = plan.tiers.map((tier, index) => {
    if (index === plan.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      const previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100 / value).toFixed(2)}`;
};

export const getMonthlyMRRStripe = (value, plan) => {
  if (!(plan && plan.monthlyPrice.tiers)) {
    return 0;
  }

  const planTiers = plan.monthlyPrice.tiers.map((tier, index) => {
    if (index === plan.monthlyPrice.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100).toFixed(2)}`;
};

export const getMonthlyMRR = (value, plan) => {
  const selectedPlan = plan && plan.phases && plan.phases.find(phase => phase.type !== 'TRIAL');

  if (selectedPlan && selectedPlan.prices && selectedPlan.prices[0]) {
    const price = Math.round(parseInt(value * selectedPlan.prices[0].value, 10)).toFixed();

    return `${price} ${selectedPlan.prices[0].currency}`
  }
  // if (!(plan && plan.monthlyPrice && plan.monthlyPrice.tiers)) {
  //   return 0;
  // }

  // const planTiers = plan.monthlyPrice.tiers.map((tier, index) => {
  //   if (index === plan.monthlyPrice.tiers.length - 1) {
  //     return { unit_amount: tier.unit_amount, up_to: 5000 };
  //   }
  //   return { ...tier, unit_amount: tier.unit_amount };
  // });

  // let price = 0;
  // let remainingUnit = +value;

  // // Logic to calculate price
  // if (+value <= +planTiers[0].up_to) {
  //   price = planTiers[0].unit_amount * value;
  // } else {
  //   planTiers.forEach((tier, index) => {
  //     let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

  //     let planPrice = price;
  //     if (remainingUnit > 0) {
  //       if (remainingUnit >= (tier.up_to - previousCount)) {
  //         planPrice = (tier.up_to - previousCount) * tier.unit_amount;
  //       } else {
  //         planPrice = remainingUnit * tier.unit_amount;
  //       }
  //       remainingUnit -= (tier.up_to - previousCount);
  //       price += planPrice;
  //     }
  //   });
  // }

  // return `${parseFloat(price / 100).toFixed(2)}`;
};

export const getMonthlyMRRPerSeatStripe = (value, plan) => {
  if (!(plan && plan.monthlyPrice.tiers)) {
    return 0;
  }

  if (value === 0) {
    return 0.0;
  }

  const planTiers = plan.monthlyPrice.tiers.map((tier, index) => {
    if (index === plan.monthlyPrice.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100 / value).toFixed(2)}`;
};


export const getMonthlyMRRPerSeat = (value, plan) => {
  const selectedPlan = plan && plan.phases && plan.phases.find(phase => phase.type !== 'TRIAL');

  if (selectedPlan && selectedPlan.prices && selectedPlan.prices[0]) {
    const price = Math.round(parseInt(selectedPlan.prices[0].value, 10));

    return `${price}`
  }
  // if (!(plan && plan.monthlyPrice && plan.monthlyPrice.tiers)) {
  //   return 0;
  // }

  // if (value === 0) {
  //   return 0.0;
  // }

  // const planTiers = plan.monthlyPrice.tiers.map((tier, index) => {
  //   if (index === plan.monthlyPrice.tiers.length - 1) {
  //     return { unit_amount: tier.unit_amount, up_to: 5000 };
  //   }
  //   return { ...tier, unit_amount: tier.unit_amount };
  // });

  // let price = 0;
  // let remainingUnit = +value;

  // // Logic to calculate price
  // if (+value <= +planTiers[0].up_to) {
  //   price = planTiers[0].unit_amount * value;
  // } else {
  //   planTiers.forEach((tier, index) => {
  //     let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

  //     let planPrice = price;
  //     if (remainingUnit > 0) {
  //       if (remainingUnit >= (tier.up_to - previousCount)) {
  //         planPrice = (tier.up_to - previousCount) * tier.unit_amount;
  //       } else {
  //         planPrice = remainingUnit * tier.unit_amount;
  //       }
  //       remainingUnit -= (tier.up_to - previousCount);
  //       price += planPrice;
  //     }
  //   });
  // }

  // return `${parseFloat(price / 100 / value).toFixed(2)}`;
};

export const getYearlyMRR = (value, plan) => {
  const selectedPlan = plan && plan.phases && plan.phases.find(phase => phase.type !== 'TRIAL');

  if (selectedPlan && selectedPlan.prices && selectedPlan.prices[0]) {
    const price = Math.round(parseInt(value * (selectedPlan.prices[0].value) / 12, 10)).toFixed(2);

    return `${price} ${selectedPlan.prices[0].currency}`
  }
  // if (!(plan && plan.yearlyPrice && plan.yearlyPrice.tiers)) {
  //   return 0;
  // }

  // const planTiers = plan.yearlyPrice.tiers.map((tier, index) => {
  //   if (index === plan.yearlyPrice.tiers.length - 1) {
  //     return { unit_amount: tier.unit_amount, up_to: 5000 };
  //   }
  //   return { ...tier, unit_amount: tier.unit_amount };
  // });

  // let price = 0;
  // let remainingUnit = +value;

  // // Logic to calculate price
  // if (+value <= +planTiers[0].up_to) {
  //   price = planTiers[0].unit_amount * value;
  // } else {
  //   planTiers.forEach((tier, index) => {
  //     let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

  //     let planPrice = price;
  //     if (remainingUnit > 0) {
  //       if (remainingUnit >= (tier.up_to - previousCount)) {
  //         planPrice = (tier.up_to - previousCount) * tier.unit_amount;
  //       } else {
  //         planPrice = remainingUnit * tier.unit_amount;
  //       }
  //       remainingUnit -= (tier.up_to - previousCount);
  //       price += planPrice;
  //     }
  //   });
  // }

  // return `${parseFloat(price / 100).toFixed(2)}`;
};

export const getYearlyMRRPerSeat = (value, plan) => {
  const selectedPlan = plan && plan.phases && plan.phases.find(phase => phase.type !== 'TRIAL');

  if (selectedPlan && selectedPlan.prices && selectedPlan.prices[0]) {
    const price = Math.round(parseInt((selectedPlan.prices[0].value) / 12, 10));

    return `${price}`
  }
  // if (!(plan && plan.yearlyPrice && plan.yearlyPrice.tiers)) {
  //   return 0;
  // }

  // if (value === 0) {
  //   return 0.0;
  // }

  // const planTiers = plan.yearlyPrice.tiers.map((tier, index) => {
  //   if (index === plan.yearlyPrice.tiers.length - 1) {
  //     return { unit_amount: tier.unit_amount, up_to: 5000 };
  //   }
  //   return { ...tier, unit_amount: tier.unit_amount };
  // });

  // let price = 0;
  // let remainingUnit = +value;

  // // Logic to calculate price
  // if (+value <= +planTiers[0].up_to) {
  //   price = planTiers[0].unit_amount * value;
  // } else {
  //   planTiers.forEach((tier, index) => {
  //     let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

  //     let planPrice = price;
  //     if (remainingUnit > 0) {
  //       if (remainingUnit >= (tier.up_to - previousCount)) {
  //         planPrice = (tier.up_to - previousCount) * tier.unit_amount;
  //       } else {
  //         planPrice = remainingUnit * tier.unit_amount;
  //       }
  //       remainingUnit -= (tier.up_to - previousCount);
  //       price += planPrice;
  //     }
  //   });
  // }

  // return `${parseFloat(price / 100 / value).toFixed(2)}`;
};

export const getYearlyMRRStripe = (value, plan) => {
  if (!(plan && plan.yearlyPrice.tiers)) {
    return 0;
  }

  const planTiers = plan.yearlyPrice.tiers.map((tier, index) => {
    if (index === plan.yearlyPrice.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100).toFixed(2)}`;
};

export const getYearlyMRRPerSeatStripe = (value, plan) => {
  if (!(plan && plan.yearlyPrice.tiers)) {
    return 0;
  }

  if (value === 0) {
    return 0.0;
  }

  const planTiers = plan.yearlyPrice.tiers.map((tier, index) => {
    if (index === plan.yearlyPrice.tiers.length - 1) {
      return { unit_amount: tier.unit_amount, up_to: 5000 };
    }
    return { ...tier, unit_amount: tier.unit_amount };
  });

  let price = 0;
  let remainingUnit = +value;

  // Logic to calculate price
  if (+value <= +planTiers[0].up_to) {
    price = planTiers[0].unit_amount * value;
  } else {
    planTiers.forEach((tier, index) => {
      let previousCount = planTiers[index - 1] ? +planTiers[index - 1].up_to : 0;

      let planPrice = price;
      if (remainingUnit > 0) {
        if (remainingUnit >= (tier.up_to - previousCount)) {
          planPrice = (tier.up_to - previousCount) * tier.unit_amount;
        } else {
          planPrice = remainingUnit * tier.unit_amount;
        }
        remainingUnit -= (tier.up_to - previousCount);
        price += planPrice;
      }
    });
  }

  return `${parseFloat(price / 100 / value).toFixed(2)}`;
};


const PERMISSION_DELETE = 40;

export const isOwner = permission => (
  permission.permissionDashboard === PERMISSION_DELETE
    && permission.permissionProfiles === PERMISSION_DELETE
    && permission.permissionDevices === PERMISSION_DELETE
    && permission.permissionApplication === PERMISSION_DELETE
    && permission.permissionModules === PERMISSION_DELETE
    && permission.permissionUsers === PERMISSION_DELETE
    && permission.permissionRoles === PERMISSION_DELETE
    && permission.permissionWorkspace === PERMISSION_DELETE
    && permission.permissionBilling === PERMISSION_DELETE
    && permission.permissionScopes === PERMISSION_DELETE
    && permission.permissionGroups === PERMISSION_DELETE
);

export const isArrayEqual = (array1, array2) => {
  if (isArray(array1) && isArray(array2)) {
    return isEmpty(xorWith(array1, array2, isEqual));
  }

  return false;
};

export const isSmallerDevice = (device) => {
  if (device === 'tablet' || device === 'mobile') {
    return true;
  }

  return false;
};

export const redirectTo = payload => history.replace(payload);

export const getInterval = {
  month: 'Monthly',
  year: 'Yearly',
};

export const getCurrencyIcon = {
  usd: <DollarSign />,
};

export const getCurrency = {
  usd: '$',
  eur: '€',
  cad: 'CAD',
  aud: 'AUD',
  gbp: '£',
  sgd: 'SGD',
};

export const getStatus = (status, name) => {
  const className = classNames(
    'data-table__status type__value text-captilize',
    {
      'data-table__status_pos': status === 'active',
      'data-table__status_neg': status === 'inactive',
      'data-table__status_warning': status === 'warning',
    },
  );

  return <div className={className}>{name}</div>;
};

export const generateRandomId = () => {
  let text = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 10; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const getHostname = () => {
  const url = new URL(window.location.origin);

  if (isLocalhost) {
    return 'dev.viso.ai';
  }

  return url.host;
};

export const toolbarOptions = [
  [{ header: 2 }],
  [{ list: 'bullet' }],
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic'],
  ['link'],
];

export const clipboard = {
  allowed: {
    tags: [
      'a',
      'b',
      'strong',
      's',
      'i',
      'p',
      'br',
      'ul',
      'ol',
      'li',
      'span',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ],
    attributes: ['href', 'rel', 'target', 'class'],
  },
  keepSelection: true,
  magicPasteLinks: true,
};

export const getIcon = {
  module: <Box strokeWidth="1" size="18" />,
  application: <GitPullRequest strokeWidth="1" size="18" />,
};

export const allowCreationBasedOnQuota = (counts, currentLimitation) => {
  if (!isNull(currentLimitation) && counts >= currentLimitation) {
    return true;
  }

  return false;
};

export const allowCreationBasedOnQuotaDeployment = (
  counts,
  currentLimitation,
) => {
  if (!isNull(currentLimitation) && counts > currentLimitation) {
    return true;
  }

  return false;
};

export const isValidCode = (country, postCode) => {
  if (!postCode) {
    return 'Zip code is required.';
  }

  const message = postalCodes.validate(country, postCode);
  if (typeof message === 'boolean') {
    return '';
  }

  if (message) {
    return 'Invalid zip code';
  }
};

export const isValidUpcomingInvoice = (currentDate, nextInvoice) => {
  const startDate = moment()
    .add(1, 'month')
    .startOf('month');
  const endDate = moment()
    .add(1, 'month')
    .endOf('month');

  const startDateThisMonth = moment().startOf('month');
  const endDateThisMonth = moment().endOf('month');

  if (
    !moment(currentDate).isBetween(startDate, endDate, 'days', true)
    // && moment(currentDate).isBetween(
    //   startDateThisMonth,
    //   endDateThisMonth,
    //   'days',
    //   true,
    // )
    // && moment(nextInvoice).isBetween(startDate, endDate, 'days', true)
  ) {
    return true;
  }

  return moment(currentDate).isBetween(startDate, endDate, 'days', true);
};

export const isValidNextInvoice = (currentDate, nextInvoice) => {
  const startDate = moment()
    .add(1, 'month')
    .startOf('month');
  const endDate = moment()
    .add(1, 'month')
    .endOf('month');

  const startDateThisMonth = moment().startOf('month');
  const endDateThisMonth = moment().endOf('month');

  if (
    !moment(currentDate).isBetween(startDate, endDate, 'days', true)
    && moment(currentDate).isBetween(
      startDateThisMonth,
      endDateThisMonth,
      'days',
      true,
    )
    && moment(nextInvoice).isBetween(startDate, endDate, 'days', true)
  ) {
    return true;
  }
};

export const getStartOfMonth = currentDate => dateFormatter(
  moment(currentDate)
    .add(1, 'month')
    .startOf('month'),
);

export const getEndOfMonth = currentDate => dateFormatter(
  moment(currentDate)
    .add(1, 'month')
    .endOf('month'),
);

export const addMonthToDate = (currentDate, month = 1) => dateFormatter(moment(currentDate).add(month, 'month'));

export const isFreePlan = (plan, interval) => {
  if (interval === 'month') {
    const totalPrice = plan.monthlyPrice
    && plan.monthlyPrice.tiers
    && plan.monthlyPrice.tiers
      .map(item => item.unit_amount)
      .reduce((total, price) => total + price);

    if (totalPrice === 0 || totalPrice === null) {
      return true;
    }
    return false;
  }

  if (interval === 'year') {
    const totalPrice = plan.yearlyPrice
    && plan.yearlyPrice.tiers
    && plan.yearlyPrice.tiers
      .map(item => item.unit_amount)
      .reduce((total, price) => total + price);

    if (totalPrice === 0 || totalPrice === null) {
      return true;
    }
    return false;
  }

  return false;
};

export const isFreePrice = () => {

  // if (interval === 'month') {
  //   const totalPrice = plan.monthlyPrice &&
  //     plan.monthlyPrice.tiers &&
  //     plan.monthlyPrice.tiers
  //       .map(item => item.unit_amount)
  //       .reduce((total, price) => total + price);

  //   if (totalPrice === 0 || totalPrice === null) {
  //     return true;
  //   }

  //   if (plan.monthlyPrice.trialPeriodDays) {
  //     if (plan.monthlyPrice.trialFee > 0) {
  //       return false;
  //     }
  //     return true;
  //   }

  //   return plan.monthlyPrice.unit_amount === 0;
  // }

  // if (interval === 'year') {
  //   const totalPrice = plan.yearlyPrice &&
  //     plan.yearlyPrice.tiers &&
  //     plan.yearlyPrice.tiers
  //       .map(item => item.unit_amount)
  //       .reduce((total, price) => total + price);

  //   if (totalPrice === 0 || totalPrice === null) {
  //     return true;
  //   }

  //   if (plan.yearlyPrice.trialPeriodDays) {
  //     if (plan.yearlyPrice.trialFee > 0) {
  //       return false;
  //     }
  //     return true;
  //   }

  //   return plan.yearlyPrice.unit_amount === 0;
  // }

  return false;
};

export const getLabels = (label) => {
  if (!label) return label;
  if (label.includes('\r\n')) {
    return label.split('\r\n');
  }
  return label.split('\n');
};

export const getDays = (date) => {
  const given = moment(date, 'YYYY-MM-DD');
  const current = moment().startOf('day');

  // Difference in number of days
  return Math.ceil(moment.duration(current.diff(given)).asDays());
};

export const checkIfProd = () => window.location.origin.includes('app.viso.ai');

export const isRoleHigherThan = (role1, role2) => (role1 && role1.level && role2 && role2.level)
 && role1.level <= role2.level;

export const isVisoUser = email => email.includes('viso.ai');

export const isNonVisoUser = (userList) => {
  if (!userList) {
    return false;
  }

  if (typeof (userList) === 'string') {
    const hasOtherEmail = !userList.endsWith('@viso.ai');

    return hasOtherEmail;
  }

  if (!userList.length) {
    return false;
  }

  const hasOtherEmail = userList.filter(user => !user.endsWith('@viso.ai'));

  return !!hasOtherEmail.length;
};

export const getVersion = (version) => {
  if (!version) {
    return null;
  }

  if (version.toString().length === 1) {
    return `V-00${version}`;
  }

  if (version.toString().length === 2) {
    return `V-0${version}`;
  }

  if (version.toString().length === 3) {
    return `V-${version}`;
  }

  return 'V-001';
};

export const getInvoiceFormat = number => {
  if (!number) {
    return `INV-0000`
  }

  const numberStr = number.toString();
  if (numberStr.length === 1) {
    return `INV-0000${numberStr}`;
  }

  if (numberStr.length === 2) {
    return `INV-000${numberStr}`;
  }

  if (numberStr.length === 3) {
    return `INV-00${number}`;
  }

  if (numberStr.length === 4) {
    return `INV-0${number}`;
  }

  return `INV-${numberStr}`;
};

export const getPriceFormat = (val) => {
  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }

  // trim the number decimal point if it exists
  let num = val.toFixed(2).toString().includes('.') ? val.toFixed(2).toString().split('.')[0] : val.toFixed(2).toString();
  let len = num.toString().length;
  let result = '';
  let count = 1;

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = ',' + result;
    }
    count++;
  }

  // add number after decimal point
  if (val.toFixed(2).toString().includes('.')) {
    result = result + '.' + val.toFixed(2).toString().split('.')[1];
  }
  // return result with - sign if negative
  result = sign < 0 ? '-' + result : result;

  return `USD ${result}`;
}

export const getAddressInfo = (user) => {
  let address = '';

  if (!user) {
    return '';
  }

  if (user.address1) {
    address = `${user.address1}`;
  }

  if (user.address2) {
    address = address ? `${address}, ${user.address2}` : `${user.address2}`;
  }

  if (user.city) {
    address = address ? `${address}, ${user.city}` : ` ${user.city}`;
  }

  if (user.postCode) {
    address = address ? `${address}, ${user.postCode}` : ` ${user.postCode}`;
  }

  return address || 'No company address';
}


export const getAddress1Info = (user) => {
  let address = '';

  if (!user) {
    return '';
  }

  if (user.address2) {
    address = `${user.address2}`;
  }

  if (user.city) {
    address = address ? `${address}, ${user.city}` : ` ${user.city}`;
  }

  if (user.postCode) {
    address = address ? `${address}, ${user.postCode}` : ` ${user.postCode}`;
  }

  return address;
}

export const getCalculationName = (dataset) => {
  if (dataset.info && dataset.info.similarityCalculationStatus
    && dataset.info.similarityCalculationStatus === 'progressing'
  ) {
    return 'similarity'
  }


  if (dataset.info && dataset.info.uniquenessCalculationStatus
    && dataset.info.uniquenessCalculationStatus === 'progressing'
  ) {
    return 'uniqueness'
  }

  return false
}

export const getLogoUrl = () => {
  const API_URL = process.env.API_URL.trim();

  if(API_URL === 'https://app.viso.ai/api' ) {
    return "https://viso-master-portal.s3.eu-central-1.amazonaws.com/logos/8e91679f-51ed-4ae3-a0cd-513fe8aa7f5b-1560374310630.jpg"
  }

  return "https://viso-dev-euc1-portal.s3.amazonaws.com/logos/8e91679f-51ed-4ae3-a0cd-513fe8aa7f5b-1560374310630.jpg"
}