import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import billingReducer from 'containers/Billing/reducer';
import loginReducer from 'containers/Login/reducer';
import partnerReducer from 'containers/Partner/reducer';
import marketplaceReducer from 'containers/Marketplace/reducer';
import libraryReducer from 'containers/Library/reducer';
import notificationsReducer from 'containers/Notification/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    login: loginReducer,
    notifications: notificationsReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    form: formReducer,
    billing: billingReducer,
    library: libraryReducer,
    solutions: partnerReducer,
    marketplace: marketplaceReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
