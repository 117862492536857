import moment from 'moment';

export const getOutput = (filterData, allResult) => {
  let result = [];
  const rangeResult = [];
  let currentResult = [];
  let finalResult = allResult;
  let isFirstLoop = true;
  let isFirstLoopRange = true;
  const isDateType = ['updated_at', 'createdAt', 'updatedAt'];
  const isRadioType = ['id', 'name', 'description', 'type', 'deviceType', 'customName', 'status', 'moduleID', 'message', 'modelType', 'dataset', 'framework', 'installed', 'installStatus', 'approval', 'status.phase', 'approvalStatus', 'metric'];

  filterData.single.forEach((single) => {
    currentResult = [];
    finalResult = [];

    const filteredValue = isFirstLoop ? allResult : result;

    const items = filteredValue.filter((item) => {
      if(single.name === 'installStatus') {
        return item[single.name] && (item[single.name]
          && item[single.name].toLowerCase()).includes(single.value && single.value.toLowerCase());
      }

      if (single.name === 'createdBy') {
        return single.value.includes(item.createdBy.id);
      }

      if (single.name === 'project') {
        return single.value.includes(item.project.id);
      }

      if (single.name === 'nodeType') {
        return single.value.includes(item.nodeType)
      }

      if (single.name === 'template') {
        return single.value.includes(item.template);
      }

      if (single.name === 'interval') {
        return single.value.toLowerCase() === item[single.name].toLowerCase();
      }

      if (single.name === 'applicationId') {
        return single.value.includes(item.application.id);
      }

      if (single.name === 'profileId') {
        
        return single.value.includes(item.profile.id);
      }

      if (single.name === 'size') {
        return (single.value && single.value.includes(item.size));
      }

      if (isRadioType.includes(single.name)) {
        if (single.name === 'status' || single.name === 'dataset' || single.name === 'framework' || single.name === 'metric') {
          return single.value.includes(item[single.name]);
        }

        if (!single.value) {
          return item[single.name];
        }

        return item[single.name] && (item[single.name]
          && item[single.name].toLowerCase()).includes(single.value && single.value.toLowerCase());
      }

      if (single.name === 'version') {
        return single.value.includes(item[single.name]);
      }

      if (single.name === 'archived' || single.name === 'isPrivateECR') {
        return item[single.name] === single.value;
      }

      if (single.name === 'status') {
        return single.value === item[single.name] || single.value === 'All';
      }

      if (single.name === 'keywords') {
        return single.value.find(value => item[single.name].toLowerCase().includes(value.toLowerCase()));
      }

      if (single.name === 'tags') {
        return single.value.find(value => item[single.name].includes(value));
      }

      return false;
    });
    isFirstLoop = false;

    result = items;

    currentResult.push(...items);
    finalResult.push(...items);
  });

  filterData.range.forEach((range) => {
    finalResult = [];
    const data = filterData.single.length ? currentResult : allResult;

    const filteredValue = isFirstLoopRange
      ? data
      : rangeResult;

    let dateRange = [];

    if (isDateType.includes(range.name)) {
      dateRange = filteredValue.filter((item) => {
        const isDateGreaterThan = moment(range.value.upper).isSameOrAfter(item[range.name]);
        const isDateSmallerThan = moment(item[range.name]).isSameOrAfter(range.value.lower);

        return isDateGreaterThan && isDateSmallerThan;
      });
    }

    if (range.name === 'usersCount') {
      dateRange = filteredValue.filter(item => item.usersCount <= range.value.upper);
    }
    if (range.type === 'singleRange') {
      dateRange = filteredValue.filter(item => item[range.name] <= range.value.upper[0]);
    }

    if (range.type === 'multiRange') {
      dateRange = filteredValue
        .filter(item => item[range.name] <= range.value.upper)
        .filter(item => item[range.name] >= range.value.lower);
    }
    // if (range.name === 'deviceCount') {
    //   dateRange = filteredValue.filter(item => item.deviceCount <= range.value.upper)
    // }

    isFirstLoopRange = false;
    rangeResult.push(...dateRange);
    finalResult.push(...dateRange);
  });

  return finalResult;
};
