import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import moment from 'moment';
import localForage from 'localforage';

import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from 'containers/Billing/components/CardForm';
import { loadStripe } from '@stripe/stripe-js';
import { defaultNotifier } from 'functions/notificationHandler';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { AlertTriangleIcon, InfoCircle, PaymentIcon } from 'images/common';
import Banner from 'components/Banner';
import { getSidebarStatus, getInfraBanner } from 'containers/App/selectors';
import { getDetailRole } from 'containers/Settings/AccessRole/selectors';
import { makeSelectFetchWorkspace, makeSelectFetchSubscription } from 'containers/Settings/Workspace/selectors';
import { routeMapping } from 'routes';
import { getPermission } from 'utils/permissions';
import { setPaymentMethod } from 'api/billing';
import { fetchWorkspaceDetailRequest } from 'containers/Settings/Workspace/actions';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class ListScroll extends Component {
  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
  }

  state = {
    paymentMethodId: '',
  }

  async componentDidMount() {
    const isNew = await localForage.getItem('isNewVersion');

    this.setState({ isNew });
  }

  onGetPaymentInfo = (data) => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: '' });
  };

  onSubmitPayment = async () => {
    if (this.state.paymentMethodId) {
      this.setState({ isLoading: true });
      try {
        const { data } = await setPaymentMethod({ paymentMethodId: this.state.paymentMethodId });
        this.setState({ isLoading: false });

        if (data.errors) {
          return defaultNotifier(data.errors);
        }

        this.setState({ showModal: false });
        defaultNotifier('Payment settings updated.');
        this.props.fetchWorkspaceDetailRequest();
      } catch (err) {
        defaultNotifier(err);
      }
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleScrollbarsMount = (scrollbars) => {
    const viewElem = scrollbars && scrollbars.view;

    this.contentRef = viewElem;
    // hack to update ref and pass down
    this.setState({});
  }

  redirectToBillingPage = () => {
    const { workspace, history } = this.props;

    this.props.router.navigate(`/${slugify(workspace.name)}${routeMapping.BILLING.path}`);
  }

  renderModal = () => {
    const { workspace } = this.props;

    const buttons = [
      <button
        className="modal__confirm button-success button button_xs button-no-shadow"
        key="confirm"
        disabled={!this.state.paymentMethodId || this.state.isLoading}
        onClick={this.onSubmitPayment}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame4"
        buttons={buttons}
        title="Confirm payment method"
        body={(
          <>
            <div className="billing-modal-info billing-info-accent0 mt_125">
              <span className="billing-info-explore">
                Please add a valid payment method to your workspace to continue
                using Viso Suite.
              </span>
            </div>
            <div className="payment_stripe_input ">
              <div className="description" />
              <div className="billing_payment_icon">
                <PaymentIcon />
                Secured by Stripe
              </div>
              <Elements stripe={stripePromise}>
                <CardForm
                  theme={
                    workspace && workspace.contrast
                  }
                  color="accent0"
                  onGetPaymentInfo={this.onGetPaymentInfo}
                  isLoading={false}
                  interval="monthly"
                />
              </Elements>
            </div>
          </>
        )}
        onClose={this.onCloseModal}
      />
    );
  };

  onCloseBanner = async () => {
    this.setState({ isNew: false });
    await localForage.setItem('isNewVersion', false);
  }

  render() {
    const {
      hideRenderView,
      sidebarStatus,
      customClass,
      detailRole,
      workspace,
      subscription,
      isProcessPage,
      showInfraBanner,
      me,
    } = this.props;

    const mainClass = classNames('content', {
      collapsed: sidebarStatus,
      [customClass]: customClass,
    });

    const permissionBilling = getPermission(
      'permissionBilling',
      detailRole.role,
    );

    const days = (subscription
      && subscription.trial_end && moment(new Date(subscription.trial_end * 1000)).diff(moment(new Date()), 'days')) || 0;

    let message = '';

    message = `Please provide a valid payment method to avoid any interruption to your Viso Suite service.
      You have ${days} day(s) left.`;

    if (days < 0) {
      message = `Your trial has ended and your workspace will be deleted in 
        ${3 + days} days if no valid payment method is provided`;
    }

    const hasModal = (workspace && (permissionBilling.includes('read') && !isProcessPage && !workspace.hasPaymentSource))
    && (showInfraBanner && !isProcessPage);

    return (
      <Scrollbars
        style={{ width: '100%', height: '100vh' }}
        renderView={(props) => {
          if (!hideRenderView) {
            return <main {...props} className={mainClass} />;
          }

          return <div {...props} />;
        }}
        universal
        autoHide
        autoHideDuration={1}
        renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
        ref={this.handleScrollbarsMount}
      >
        <div className="content-wrapper">
          {/* {me && me.isOwner && this.state.showModal && this.renderModal()} */}
          <div className="content-body">
            {/* {this.state.isNew && <Banner
              title=''
              color='accent0'
              onClose={this.onCloseBanner}
              desc={<div>We’ve just released an optimized workspace navigation.
                {' '}<a href='https://viso.ai/product/workspace-navigation' target='_blank' className='banner-href' rel='noreferrer'>Learn more</a></div>}
            />
            } */}
            {/* {me && me.isOwner && (permissionBilling.includes('read') && !isProcessPage && !workspace.hasPaymentSource && (
              <Banner
                icon={<AlertTriangleIcon />}
                title="Add payment method."
                color="accent4"
                desc={message}
                link="Update payment settings"
                action={() => this.setState({ showModal: true })}
              />
            ))} */}
            {/* {(workspace && !workspace.builderInstalled && !isProcessPage && (
              <Banner
                icon={<InfoCircle />}
                title="Your infrastructure."
                color="accent0"
                desc="We are setting up your infrastructure in the background. The Viso Builder will be ready in a few minutes."
              />
            ))} */}
            <div className={classNames({
              'content-body-padding': hasModal,
            })}
            >
              {this.contentRef.current !== null && this.props.children(this.contentRef)}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sidebarStatus: getSidebarStatus(),
  detailRole: getDetailRole(),
  me: makeSelectMe(),
  subscription: makeSelectFetchSubscription(),
  workspace: makeSelectFetchWorkspace(),
  showInfraBanner: getInfraBanner(),
});

const withConnect = connect(
  mapStateToProps, { fetchWorkspaceDetailRequest },
);

ListScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  hideRenderView: PropTypes.bool,
};

export default withRouter(withConnect(ListScroll));
